import React from 'react'
import { graphql } from 'gatsby'

import { SEO } from '@renderbus/common/components'

import Layout from '../molecules/layout'
import {
  AsperaGreyContainer,
  AsperaWhiteContainer,
  AsperaSmallDarkContainer,
  InternalContainer,
  InternalFlexContainer,
  AsperaSpeedFlexContainer,
  AsperaConcatFlexContainer,
  AsperaSpeedTableWrapper,
  H1,
  H2,
  AsperaSpeedH3,
  Button,
  WorldMapSvg,
  PCImage,
  ImageMoible,
  AsperaWechatWrapper,
  AsperaWechatClose,
  AsperaConcatBoard,
} from './aspera.atom'
import {
  SupportContainer,
  ChallengeContainer,
  SupportWrapper,
  H3,
  SubTitleContainer,
  SubTitleLayout1,
  SupportName,
  AsperaWechat,
  ButtonWrapper,
  FixedWidthContainer,
} from './aspera.atom'
import { ChallengeCard, ContainerBox, CardTitle1, CardTitle2, CardText } from './aspera.atom'

import { AsperaSpeedContainer, GreyText, AsperaVideo } from './aspera.atom'

import { AsperaFeatureBox, AsperaSpeedTable } from './aspera.atom'

import Support11 from '../images/aspera/Support11'
import Support12 from '../images/aspera/Support12'
import Support13 from '../images/aspera/Support13'
import Support14 from '../images/aspera/Support14'

import Challenge11 from '../images/aspera/Challenge11'
import Challenge12 from '../images/aspera/Challenge12'
import Challenge13 from '../images/aspera/Challenge13'
import TcpSvg from '../images/aspera/Tcp'

import Feature11 from '../images/aspera/Feature11'
import Feature12 from '../images/aspera/Feature12'
import Feature13 from '../images/aspera/Feature13'
import Feature21 from '../images/aspera/Feature21'
import Feature22 from '../images/aspera/Feature22'
import Feature23 from '../images/aspera/Feature23'
import Com from '../images/aspera/Com.png'
import ComMoible from '../images/aspera/company-mobile.png'

import Wechat from '../images/aspera/aspera-wechat.png'
import CloseIcon from '../images/aspera/close.png'

class Aspera extends React.PureComponent {
  state = {
    showWechat1: false,
    showWechat2: false,
    showWechat3: false,
  }

  wechatRef = null

  setRef = ref => {
    this.wechatRef = ref
  }

  switchWechatVisible = (showWechat, index) => {
    this.setState({ [`showWechat${index}`]: showWechat })
  }

  handleClick = event => {
    if (event.target !== this.wechatRef) {
      this.switchWechatVisible(false, 1)
      this.switchWechatVisible(false, 2)
      this.switchWechatVisible(false, 3)
    }
  }

  componentDidMount = () => {
    window.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
  }

  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props

    const { showWechat1, showWechat2, showWechat3 } = this.state

    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='IBM Aspera软件 - 企业跨国快速传输大文件解决方案'
          keywords='远距离传输大型文件, 跨国快速传输大文件, 国外大文件传输'
          description='IBM Aspera 软件为企业跨国传输大型文件、海量数据集并快速移动大数据解决方案，瑞云已和IBM达成深度合作，我们将为您提供更优折扣价格以及本地化技术支持，售后服务。'
        />
        <AsperaGreyContainer header>
          <FixedWidthContainer>
            <InternalContainer>
              <H1>IBM Aspera®软件</H1>
              <H2>
                企业长距离传输大型文件、海量数据集并快速移动大数据解决方案，速度可比FTP快1000倍！
              </H2>
              <ButtonWrapper>
                <Button
                  id='aspera'
                  onClick={e => {
                    e.stopPropagation()
                    this.switchWechatVisible(true, 1)
                  }}
                >
                  申请免费体验
                </Button>
                {showWechat1 && (
                  <AsperaWechatWrapper>
                    <AsperaConcatBoard>
                      <div style={{ margin: '10px auto 5px' }}>扫一扫，快速沟通</div>
                      <AsperaWechat className='aspera-wechat' ref={this.setRef} src={Wechat} />
                      <div>联系人：沈小姐</div>
                      <div>联系电话：18948780649</div>
                      <AsperaWechatClose
                        src={CloseIcon}
                        alt='CLOSE'
                        onClick={() => this.switchWechatVisible(false, 1)}
                      />
                    </AsperaConcatBoard>
                  </AsperaWechatWrapper>
                )}
              </ButtonWrapper>

              <WorldMapSvg />
            </InternalContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='aspera-container--dark'>
          <FixedWidthContainer>
            <H3>Aspera,让您的数据安全地传输到任何指定的地方 </H3>
            <SubTitleContainer>
              <SubTitleLayout1>
                IBM Aspera® 软件利用基于获奖的 IBM FASP®
                协议构建的快速文件传输和流式解决方案，可跨任何距离移动任意规模的数据。支持您的企业传输大型文件、海量数据集并快速移动大数据，满足您的文件协作、同步、自动化管理和数据迁移需求。瑞云已和IBM达成深度合作，成为IBM
                Aspera® 软件的中国区代理，我们将为您提供：
              </SubTitleLayout1>
              <SubTitleLayout1></SubTitleLayout1>
            </SubTitleContainer>
            <SupportContainer wrapInMobile>
              <SupportWrapper>
                <Support11 style={{ width: 84, marginTop: 6 }} />
                <SupportName className='free-detect'>免费测试体验</SupportName>
              </SupportWrapper>
              <SupportWrapper>
                <Support12 />
                <SupportName>更优折扣价格</SupportName>
              </SupportWrapper>
              <SupportWrapper>
                <Support13 />
                <SupportName className='technology-support'>本地化技术支持</SupportName>
              </SupportWrapper>
              <SupportWrapper>
                <Support14 style={{ width: 72 }} />
                <SupportName>本地化售后服务</SupportName>
              </SupportWrapper>
            </SupportContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='overflow-hidden'>
          <FixedWidthContainer>
            <InternalContainer>
              <H3>TCP及其替代技术的挑战</H3>
              <SubTitleContainer wrapInMobile={false}>
                <SubTitleLayout1>
                  Web浏览,FTP传输工具,部分文件传输服务通常依赖于传输控制协议（TCP）,在理想条件下,TCP可提供可靠的数据传输，但随着传输距离的增加或网络条件的降低，在进行大规模数据传输时,就会出现严重的性能瓶颈。
                </SubTitleLayout1>
              </SubTitleContainer>

              <ChallengeContainer>
                <SupportWrapper>
                  <Challenge11 style={{ width: 98, marginTop: 5 }} />
                  <SupportName className='challenge-name'>Web浏览</SupportName>
                </SupportWrapper>
                <SupportWrapper>
                  <Challenge12 style={{ width: 97 }} />
                  <SupportName className='challenge-name'>FTP</SupportName>
                </SupportWrapper>
                <SupportWrapper>
                  <Challenge13 style={{ width: 100 }} />
                  <SupportName className='challenge-name file-translate' style={{ marginTop: 30 }}>
                    文件传输
                  </SupportName>
                </SupportWrapper>
              </ChallengeContainer>

              <ChallengeCard>
                <TcpSvg />
                <ContainerBox>
                  <CardTitle1>TCP</CardTitle1>
                  <CardTitle2>TCP性能随距离增加而降低</CardTitle2>
                  <CardText>吞吐量的瓶颈随时延及丢包率的增加而更加明显 </CardText>

                  <CardTitle2>TCP的吞吐量没有随着带宽的扩展而扩展</CardTitle2>
                  <CardText>TCP的最初设计目的是适应低带宽</CardText>
                  <CardText>增加更多的带宽不会提高吞吐量</CardText>

                  <CardTitle2>距离对网络影响</CardTitle2>
                  <CardText>延迟增加</CardText>
                  <CardText>丢包率增加</CardText>
                  <CardText>快速网络随着距离增加而降低服务质量</CardText>
                </ContainerBox>

                <ContainerBox>
                  <CardTitle1>其它TCP替代技术</CardTitle1>
                  <CardText className='card-text--predot'>
                    基于TCP - 网络延迟及丢包率必须低{' '}
                  </CardText>
                  <CardText className='card-text--predot'>UDP - 带宽利用率比较低</CardText>
                  <CardText className='card-text--predot'>
                    数据缓冲 - 不适用于大量大文件的传输及工作
                  </CardText>
                  <CardText className='card-text--predot'>
                    优化的TCP - 提高了性能，但对于快速网络而言效率还是比较低下
                  </CardText>
                  <CardText className='card-text--predot'>
                    数据压缩 - 对于特定文件，时间消耗及传输效率不受影响
                  </CardText>
                  <CardText className='card-text--predot'>
                    CDNs - overhead高，扩展成本非常昂贵
                  </CardText>
                </ContainerBox>
              </ChallengeCard>
            </InternalContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='aspera-container--dark'>
          <FixedWidthContainer>
            <InternalFlexContainer mobileDirection='column'>
              <AsperaSpeedContainer>
                <AsperaSpeedH3>Aspera，轻松实现大型数据长距离快速传输</AsperaSpeedH3>
                <SubTitleContainer mobileTextAlign='left' className='aspera-speed--content'>
                  <GreyText className='aspera-speed--text'>
                    借助Aspera大文件传输方案，可充分利用可用带宽，将您的数据以比目前快10倍、100倍、甚至是1000倍的速度安全传输到世界任何地方，它独特的自适应速率控制可交付高性能但不影响其他业务关键网络流量的服务。
                  </GreyText>
                </SubTitleContainer>
                <SubTitleContainer mobileTextAlign='left' className='aspera-speed--content'>
                  <GreyText className='aspera-speed--text'>
                    此外，Aspera通过加密和数据完整性验证，确保静态数据和动态数据的安全传输，无论数据规模、传输距离或网络条件如何，将大幅度提升您的数据传输速度与安全，进一步推动您企业的全球业务发展。
                  </GreyText>
                </SubTitleContainer>
              </AsperaSpeedContainer>
              <AsperaVideo>
                <video autoPlay={true} muted loop playsInline={true}>
                  <source
                    type='video/mp4'
                    src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/IBM-Aspera-Overview.mp4'
                    rel='nofollow'
                  />
                </video>
              </AsperaVideo>
            </InternalFlexContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='aspera-container--light-dark contact-us'>
          <FixedWidthContainer>
            <InternalFlexContainer mobileDirection='column' className='contact-us'>
              <div className='text'>
                想要体验海量数据、大文件安全快速传输，立即联系我们获得Aspera免费试用机会
              </div>
              <ButtonWrapper>
                <Button
                  id='aspera'
                  onClick={e => {
                    e.stopPropagation()
                    this.switchWechatVisible(true, 2)
                  }}
                >
                  联系我们
                </Button>
                {showWechat2 && (
                  <AsperaWechatWrapper className='concat-us'>
                    <AsperaConcatBoard>
                      <div style={{ margin: '10px auto 5px' }}>扫一扫，快速沟通</div>
                      <AsperaWechat className='aspera-wechat' ref={this.setRef} src={Wechat} />
                      <div>联系人：沈小姐</div>
                      <div>联系电话：18948780649</div>
                    </AsperaConcatBoard>
                    <AsperaWechatClose
                      src={CloseIcon}
                      alt='CLOSE'
                      onClick={() => this.switchWechatVisible(false, 2)}
                    />
                  </AsperaWechatWrapper>
                )}
              </ButtonWrapper>
            </InternalFlexContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='overflow-hidden'>
          <FixedWidthContainer>
            <H3>Aspera，实现跨距离高速同步与高效协作</H3>
            <SubTitleContainer>
              <GreyText style={{ width: 'auto', textAlign: 'center' }}>
                Aspera大文件传输方案，在FASP的基础上，构建了一套完整的随时可用的应用和解决方案，用于传输、协作、自动化、同步和流处理
              </GreyText>
            </SubTitleContainer>
            <AsperaSpeedFlexContainer className='flex-container--aspera-speed'>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature11 />
                </div>
                <div className='feature-box'>
                  <div className='title'>大数据传输与同步</div>
                  <div className='content'>
                    全局快速传输、分发和同步大型文件、海量小文件、数据集。
                  </div>
                </div>
              </AsperaFeatureBox>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature12 />
                </div>
                <div className='feature-box'>
                  <div className='title'>大型文件发送和共享</div>
                  <div className='content'>加速与世界各地的团队协作处理大数据和大型文件。</div>
                </div>
              </AsperaFeatureBox>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature13 />
                </div>
                <div className='feature-box'>
                  <div className='title'>传输自动化和管理</div>
                  <div className='content'>全面自动统筹、监控和控制数据传输和工作流程。</div>
                </div>
              </AsperaFeatureBox>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature21 />
                </div>
                <div className='feature-box'>
                  <div className='title'>任何比特率流式方法</div>
                  <div className='content'>
                    全局快速传输、分发和同步大型文件、海量小文件、数据集。
                  </div>
                </div>
              </AsperaFeatureBox>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature22 />
                </div>
                <div className='feature-box'>
                  <div className='title'>混合云工作流程</div>
                  <div className='content'>架构可在本地和/或云中运行的高度可扩展的工作流程。</div>
                </div>
              </AsperaFeatureBox>
              <AsperaFeatureBox>
                <div className='svg-wrapper'>
                  <Feature23 />
                </div>

                <div className='feature-box'>
                  <div className='title'>安全资产交换</div>
                  <div className='content'>
                    使用区块链技术，通过多云架构添加额外的安全层，以进行数字资产移动。
                  </div>
                </div>
              </AsperaFeatureBox>
            </AsperaSpeedFlexContainer>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaGreyContainer className='aspera-container--dark overflow-hidden'>
          <FixedWidthContainer>
            <H3>Aspera传输的优越性</H3>
            <AsperaSpeedTableWrapper>
              <AsperaSpeedTable>
                <tbody>
                  <tr className='aspera-speed-table-header'>
                    <td>出发点</td>
                    <td>目的地</td>
                    <td>操作系统</td>
                    <td>链路类型</td>
                    <td>理论带宽</td>
                    <td>FTP/HTTP</td>
                    <td>FASP</td>
                    <td>速度提升</td>
                  </tr>
                  <tr>
                    <td>美国洛杉矶</td>
                    <td>中国上海</td>
                    <td>Win to Linux</td>
                    <td>互联网</td>
                    <td>25 Mbps</td>
                    <td>1 Mbps</td>
                    <td>24 Mbps</td>
                    <td>24X</td>
                  </tr>
                  <tr>
                    <td>中国深圳</td>
                    <td>中国北京</td>
                    <td>Linux to Linux</td>
                    <td>数据中心至数据中心</td>
                    <td>100 Mbps</td>
                    <td>10.9 Mbps</td>
                    <td>96.6 Mbps</td>
                    <td>8.8X</td>
                  </tr>
                  <tr>
                    <td>中国上海</td>
                    <td>中国广州</td>
                    <td>Win to Win</td>
                    <td>互联网</td>
                    <td>100 Mbps</td>
                    <td>24.2 Mbps</td>
                    <td>98.3 Mbps</td>
                    <td>4X</td>
                  </tr>
                  <tr>
                    <td>韩国首尔</td>
                    <td>中国上海</td>
                    <td>Win to Win</td>
                    <td>互联网</td>
                    <td>30 Mbps</td>
                    <td>1.39 Mbps</td>
                    <td>29.1 Mbps</td>
                    <td>21X</td>
                  </tr>
                  <tr>
                    <td>印度孟买</td>
                    <td>中国深圳</td>
                    <td>Win to Win</td>
                    <td>互联网</td>
                    <td>200 Mbps</td>
                    <td>3.7 Mbps</td>
                    <td>190 Mbps</td>
                    <td>50X</td>
                  </tr>
                  <tr>
                    <td>德国柏林</td>
                    <td>中国深圳</td>
                    <td>Win to Win</td>
                    <td>互联网</td>
                    <td>20 Mbps</td>
                    <td>0.38 Mbps</td>
                    <td>18.9 Mbps</td>
                    <td>52X</td>
                  </tr>
                  <tr>
                    <td>中国深圳</td>
                    <td>中国北京</td>
                    <td>Linux to Linux</td>
                    <td>数据中心至数据中心</td>
                    <td>500 Mbps</td>
                    <td>25 Mbps</td>
                    <td>320 Mbps</td>
                    <td>12X</td>
                  </tr>
                </tbody>
              </AsperaSpeedTable>
            </AsperaSpeedTableWrapper>
          </FixedWidthContainer>
        </AsperaGreyContainer>

        <AsperaWhiteContainer>
          <FixedWidthContainer>
            <H3 style={{ color: '#000' }}>Aspera庞大的用户群</H3>
            <PCImage className='company-pc' src={Com} alt='company' />
            <ImageMoible className='company-mobile' src={ComMoible} alt='company' />
          </FixedWidthContainer>
        </AsperaWhiteContainer>

        <AsperaSmallDarkContainer>
          <FixedWidthContainer>
            <AsperaConcatFlexContainer mobileDirection='column' className='footer-container'>
              <div className='text'>
                <div> 联系我们购买可享受比Aspera官网更优折扣，同时享受Aspera本地化的技术支持，</div>
                <div>想要了解更多关于Aspera大文件传输方案以及获取免费体验，欢迎咨询!</div>
              </div>
              <ButtonWrapper className='contact-btn-wrapper'>
                <Button
                  id='aspera'
                  onClick={e => {
                    e.stopPropagation()
                    this.switchWechatVisible(true, 3)
                  }}
                >
                  申请Aspera免费体验
                </Button>
                {showWechat3 && (
                  <AsperaWechatWrapper className='free-trial'>
                    <AsperaConcatBoard>
                      <div style={{ margin: '10px auto 5px' }}>扫一扫，快速沟通</div>
                      <AsperaWechat className='aspera-wechat' ref={this.setRef} src={Wechat} />
                      <div>联系人：沈小姐</div>
                      <div>联系电话：18948780649</div>
                    </AsperaConcatBoard>
                    <AsperaWechatClose
                      src={CloseIcon}
                      alt='CLOSE'
                      onClick={() => this.switchWechatVisible(false, 3)}
                    />
                  </AsperaWechatWrapper>
                )}
              </ButtonWrapper>
            </AsperaConcatFlexContainer>
          </FixedWidthContainer>
        </AsperaSmallDarkContainer>
      </Layout>
    )
  }
}

export default Aspera

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/about/bg.png/" }) {
      ...fluidImage
    }
    teamPhoto: file(relativePath: { regex: "/about/about-college-2021.png/" }) {
      ...fluidImage
    }
  }
`
